<template>
  <v-container>
    <div>
      <v-dialog
        v-model="filtersActive"
        scrollable
        max-width="1000px"
        persistent
      >
        <v-card>
          <v-card-title class="mx-0 px-0 my-0 py-0">
            <v-toolbar
              color="primary"
              class="mx-0 px-0 my-0 py-0"
              fixed
              top
              dark
            >
              <v-icon @click="toggleFilter(), isFilterSet()">mdi-close</v-icon>
              <v-toolbar-title class="flex text-center"
                >Artikelsuche Filtern <NameSearchHelpDialog
              /></v-toolbar-title>
            </v-toolbar>
          </v-card-title>

          <v-card-text
            ><v-container justify="center" class="mt-15 mb-4">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="articlenumber"
                    class="mx-sm-1"
                    outlined
                    label="Artikelnummer"
                    clearable
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="articlenumber_2"
                    class="mx-sm-1"
                    outlined
                    label="Artikelnummer 2"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="articledescription"
                    class="mx-sm-1"
                    outlined
                    label="Artikelbezeichnung"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    class="mx-sm-1"
                    outlined
                    :items="allarticletypeitems"
                    item-value="id"
                    v-model="articletype"
                    return-object
                    :item-text="item => item.description"
                    label="Artikeltype"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="articlecategory"
                    class="mx-sm-1"
                    outlined
                    label="Artikelkategorie"
                    clearable
                    :items="allarticlecategoryitems"
                    item-text="description"
                    item-value="id"
                    :disabled="articletype == null ? true : false"
                  ></v-autocomplete>
                </v-col>

                <v-col>
                  <v-autocomplete
                    class="mx-sm-1"
                    outlined
                    :items="allarticlegroupitems"
                    item-value="id"
                    v-model="articlegroup"
                    return-object
                    item-text="description"
                    label="Artikelgruppe"
                    clearable
                    :disabled="articletype == null ? true : false"
                  ></v-autocomplete>
                </v-col>
              </v-row> </v-container
          ></v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="clearFilter()">Filter aufheben</v-btn>
            <v-btn
              class="primary"
              @click="searchTrigger(), toggleFilter(), isFilterSet()"
            >
              Suchen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row class="mt-1" align="center" justify="center">
        <v-col cols="12" sm="9">
          <v-text-field
            class="px-8 mt-8"
            v-model="fullTextSearchInput"
            append-icon="mdi-magnify"
            label="Textsuche (mind. 3 Zeichen)"
            :rules="[twoCharactersValidation]"
            clearable
            @input="searchTrigger"
            underlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1">
          <v-btn
            v-if="!filterSet && !isMobile()"
            block
            class="seondary"
            @click="toggleFilter"
          >
            <v-icon class="mr-2">
              mdi-filter-outline
            </v-icon>

            Filter
          </v-btn>

          <v-btn
            v-else-if="!filterSet && isMobile()"
            block
            class="seondary"
            @click="toggleFilter"
          >
            <v-icon>
              mdi-filter-outline
            </v-icon>
          </v-btn>

          <v-btn
            v-else-if="filterSet && isMobile()"
            block
            class="primary"
            @click="toggleFilter"
          >
            <v-icon>
              mdi-filter
            </v-icon>
          </v-btn>

          <v-btn v-else block class="primary" @click="toggleFilter">
            <v-icon class="mr-2">
              mdi-filter
            </v-icon>

            Filter
          </v-btn>
        </v-col>

        <!--<ArticleFilter
            @toggle="filterSet = !filterSet"
            @searchTriggered="initiateSearchUI(), scrollToResults()"
            @searchSuccess="setResults"
          />-->
      </v-row>
      <v-divider class="mt-6"></v-divider>
      <div ref="resultsTable">
        <v-data-table
          :headers="headers"
          :items="results"
          :items-per-page="10"
          class="mt-6 datatable-row-pointer"
          @click:row="openArticleDataTable"
          :loading="loading"
          loading-text="Artikel werden geladen ..."
          v-if="results.length > 0"
        >
          <template v-slot:item.name="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-avatar
                  v-on="on"
                  size="34"
                  :color="item.active ? 'white' : 'red'"
                  class="mr-4 elevation-2"
                >
                  <v-icon :color="item.active ? 'black' : 'white'">{{
                    articleType(item).icon
                  }}</v-icon>
                </v-avatar>
              </template>
              <span>{{ articleType(item).name }}</span
              ><br />
              <span v-if="!item.active">inaktiv</span>
            </v-tooltip>
            {{ item.name }}
          </template>

          <template v-slot:no-data> Keine Daten gefunden. </template>

          <template v-slot:footer.prepend>
            <!-- <v-spacer /> -->
            <v-btn
              small
              depressed
              color="white"
              @click="scrollToTop()"
              class="ml-2"
            >
              Nach oben
              <v-icon>mdi-arrow-up-thin</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
      <v-layout justify-center align-center>
        <v-flex shrink>
          <v-img
            class="mt-16"
            src="@/assets/svg/undraw_empty.svg"
            max-width="350px"
            v-if="results.length == 0 && loading == false"
          ></v-img>
        </v-flex> </v-layout
      ><br />
      <h2
        align="center"
        v-if="
          results.length == 0 &&
            fullTextSearchInput.length <= 2 &&
            loading == false
        "
      >
        Es konnten keine Artikel gefunden werden.
      </h2>
      <h2
        align="center"
        v-if="
          results.length == 0 &&
            fullTextSearchInput.length > 2 &&
            loading == false
        "
      >
        Zu dieser Suche konnten keine Artikel gefunden werden.
      </h2>

      <!-- <div class="text-center" v-if="searchSuccessfull && results.length == 0">
      <v-img
        class="mx-auto mt-4 mb-8"
        src="@/assets/svg/undraw_empty_xct9.svg"
        max-width="250px"
      ></v-img>
      Keine Daten gefunden. Bitte überprüfen Sie Ihre Eingabe.
    </div> -->
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import axios from 'axios'
import articleService from '@/services/article/filter.js'
//import ArticleFilter from '@/views/article/ArticleFilter.vue'
import NameSearchHelpDialog from '@/components/crm/addresses/NameSearchHelpDialog.vue'
export default {
  components: {
    NameSearchHelpDialog
  },
  data: () => ({
    fullTextSearchUrl: '/v1/articles/search',
    fullTextSearchInput: '',

    filterSet: false,

    searchSuccessfull: false,
    results: [],
    cancelToken: undefined,
    loading: false,
    searchStartedOnce: false,
    akey: {
      firma: null,
      artNr: null
    },
    aartNr2: null,
    aarticleDescription: null,
    aarticleType: {
      key: {
        firma: null,
        tpCode: null
      },
      description: null
    },
    aarticleCategory: {
      key: {
        firma: null,
        lfdnr: null
      },
      description: null
    },
    aarticleGroup: {
      key: {
        firma: null,
        lfdnr: null
      },
      description: null
    },
    headers: [
      {
        text: 'Artikelnummer',
        align: 'start',
        sortable: true,
        value: 'artNr'
      },
      { text: 'Artikelnummer 2', value: 'artNr2' },
      { text: 'Artikelbezeichnung', value: 'articleDescription' },
      { text: 'Artikelbezeichnung2', value: 'articleDescription2' }
    ],
    articlenumber: null,
    articlenumber_2: null,
    articledescription: null,
    articlecategory: null,
    articletype: null,
    articlegroup: null,

    allarticlegroupitems: [],
    allarticletypeitems: [],
    allarticlecategoryitems: [],

    filtersActiveIndex: 1
    //filterSet: false
  }),
  computed: {
    ...mapState({
      filtersActive: state => state.crmFilter.filterActivated
    }),
    ...mapGetters(['getFilter']),
    id() {
      // Berechnungslogik für "id"...
      return this.someValue + this.anotherValue
    }
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    },
    routeIncludes(search) {
      if (this.$route.matched.some(route => route.path.includes(search))) {
        return true
      }
      return false
    },

    initiateSearchUI() {
      this.loading = true
      this.results = []
    },

    searchFullText(query) {
      if (query == undefined) return

      this.initiateSearchUI()
      //Check if there are any previous pending requests
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel('Operation canceled due to new request.')
      }
      this.cancelToken = axios.CancelToken.source()
      axios
        .get(this.fullTextSearchUrl, {
          params: {
            q: query
          },
          cancelToken: this.cancelToken.token
        })
        .then(response => {
          const array = []
          for (var i in response.data) {
            array.push([i, response.data[i]])
          }
          this.results = response.data
          this.searchSuccessfull = true
          this.loading = false
        })
        .catch(err => {
          console.log('error searching article: ' + err)
        })
    },
    searchTrigger() {
      if (this.filtersActive) {
        this.searchStartedOnce = true
        this.removeQueryParam()
        this.searchArticlesFiltered()
        this.searchFullText(this.fullTextSearchInput)
      }
      //Volltextsuche
      else {
        if (
          this.fullTextSearchInput != null &&
          this.fullTextSearchInput != '' &&
          String(this.fullTextSearchInput).length > 2
        ) {
          this.searchStartedOnce = true
          this.$router.push({ query: { q: this.fullTextSearchInput } })
          this.searchFullText(this.fullTextSearchInput)
        } else {
          this.removeQueryParam()
        }
      }
    },
    setResults(value) {
      this.results = value
    },
    removeQueryParam() {
      this.$router.replace({ query: {} }).catch(() => {})
    },
    openArticle(articleNumber) {
      this.$store.dispatch('setSaveFilter', {
        articlenumber: this.articleNumber,
        articlenumber_2: this.articleNumber_2,
        articledescription: this.articleDescription,
        articletype: this.articleType,
        articlecategory: this.articleCategory,
        articlegroup: this.articleGroup
      })
      this.$router.push({
        name: 'Artikeldetails',
        params: { articleNumber: articleNumber }
      })
    },
    openArticleDataTable(value) {
      this.openArticle(value.artNr)
      this.$store.state.articleDetails.articleExists = true
    },
    articleType(item) {
      return articleService.getArticleTypeDetailed(item.articleTypes[0])
    },
    scrollToTop() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    scrollToResults() {
      const element = this.$refs['resultsTable']
      if (element) {
        this.$nextTick(() => {
          element.scrollIntoView({ behavior: 'smooth' })
        })
      }
    },
    twoCharactersValidation(value) {
      return (
        this.filtersActive ||
        (!!value && String(value).length > 2) ||
        'Bitte geben Sie mehr als zwei Zeichen ein'
      )
    },
    toggleFilter() {
      this.$store.dispatch('toggleFilterActivated')

      if (this.filtersActive == true) {
        this.fullTextSearchInput = ''
      }
      this.$emit('toggle')
    },
    clearFilter() {
      this.articlenumber = null
      this.articlenumber_2 = null
      this.articledescription = null
      this.articlecategory = null
      this.articletype = null
      this.articlegroup = null
      this.results = []
    },
    isFilterSet() {
      if (
        this.articlenumber != null ||
        this.articlenumber_2 != null ||
        this.articledescription != null ||
        this.articlecategory != null ||
        this.articletype != null ||
        this.articlegroup != null
      ) {
        this.filterSet = true
      } else {
        this.filterSet = false
      }
    },
    resetFilter() {
      this.clearFilter()
      this.$store.dispatch('resetFilter')
    },

    init_filter() {
      axios
        .get('/v1/articles/types', {})
        .then(response => {
          const array = []

          response.data.forEach(elem => {
            array.push(elem)
          })
          this.allarticletypeitems = array

          this.searchSuccessfull = true
          this.loading = false
        })
        .catch(err => {
          console.log('error loading in filter' + err)
        })
    },
    /*searchTrigger() {
      if (this.filterActive) {
        this.searchStartedOnce = true
        this.removeQueryParam()
        this.searchArticlesFiltered()
      }
    },*/
    loadData() {
      this.$store.dispatch('loadArticleTypes')
      this.$store.dispatch('loadArticleCategories')
      this.$store.dispatch('loadArticleGroups')
      //this.$store.dispatch('loadVisibleTabs')

      const storedFilter = this.$store.getters.getFilter
      this.articlenumber = storedFilter.articlenumber
      this.articlenumber_2 = storedFilter.articlenumber2
      this.articledescription = storedFilter.articledescription
      this.articletype = storedFilter.articletype
      this.articlecategory = storedFilter.articlecategory
      this.articlegroup = storedFilter.articlegroup
      this.filtersActiveIndex = this.filtersActive ? 0 : 1
    },
    searchArticlesFiltered() {
      this.akey.artNr = this.articlenumber
      this.aartNr2 = this.articlenumber_2
      this.aarticleDescription = this.articledescription

      if (this.articletype != '' && this.articletype != null)
        this.aarticleType = this.articletype
      else if (this.articletype == null || this.articletype == '') {
        this.aarticleType = {
          key: {
            firma: null,
            tpCode: null
          },
          description: null
        }
      }
      if (this.articlecategory != '' && this.articlecategory != null)
        this.aarticleCategory = this.articlecategory
      else if (this.articlecategory == null || this.articlecategory == '') {
        this.aarticleCategory = {
          key: {
            firma: null,
            lfdnr: null
          },
          description: null
        }
      }
      if (this.articlegroup != '' && this.articlegroup != null)
        this.aarticleGroup = this.articlegroup
      else if (this.articlegroup == null || this.articlegroup == '') {
        this.aarticleGroup = {
          key: {
            firma: null,
            lfdnr: null
          },
          description: null
        }
      }
      this.filterArticle(
        this.akey,
        this.aartNr2,
        this.aarticleDescription,
        this.aarticleType,
        this.aarticleCategory,
        this.aarticleGroup
      )
    },

    filterArticle(
      akey,
      aartNr2,
      aarticleDescription,
      aarticleType,
      aarticleCategory,
      aarticleGroup
    ) {
      this.initiateSearchUI()
      console.warn(akey)
      articleService
        .filterArticle(
          akey,
          aartNr2,
          aarticleDescription,
          aarticleType,
          aarticleCategory,
          aarticleGroup
        )
        .then(data => {
          this.results = data
          this.loading = false
        })
        .catch(err => {
          console.log('error searching article: ' + err)
          this.loading = false
        })
    }
  },
  watch: {
    articletype(newValue) {
      if (newValue === null) {
        this.articlecategory = null
        this.articlegroup = null
        return
      }
      axios
        .post(
          '/v1/articlesFilteredDependencies/dependenciesCategories',
          newValue
        )
        .then(response => {
          const array = []

          response.data.forEach(elem => {
            array.push({
              description: elem.description,
              id: elem.key.lfdnr
            })
          })
          this.allarticlecategoryitems = array

          this.searchSuccessfull = true
          this.loading = false
        })
        .catch(err => {
          console.log('error loading in filter' + err)
        })
      axios
        .post('/v1/articlesFilteredDependencies/dependenciesGroups', newValue)
        .then(response => {
          const array = []

          response.data.forEach(elem => {
            array.push({
              description: elem.description,
              id: elem.key.lfdnr
            })
          })
          this.allarticlegroupitems = array

          this.searchSuccessfull = true
          this.loading = false
        })
        .catch(err => {
          console.log('error loading in filter' + err)
        })
    },
    // Workaround for clearing validation when opening filter
    filtersActive() {
      if (this.filtersActive) {
        this.fullTextSearchInput = ' '
        this.$nextTick(() => {
          this.fullTextSearchInput = ''
        })
      }
    }
  },
  created() {
    window.addEventListener('keydown', event => {
      if (event.key == 'Enter') this.searchTrigger()
    })
  },
  mounted() {
    if (this.$route.query['q'] != null) {
      this.fullTextSearchInput = this.$route.query['q']
      this.$store.dispatch('unsetFilterActive')
      this.searchTrigger()
    }
    this.filtersActiveIndex = this.filtersActive ? 0 : 1
    this.init_filter()
  }
}
</script>

<style lang="css" scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
